
#accounting_summary{
	display: flex;
	justify-content: space-between;

	> *{
		margin: $grid-gutter-width /2;
	}
	> *:first-child{
		margin-left: 0;
	}
	> *:last-child{
		margin-right: 0;
	}
}