
//none
//free
//to_bill
//waiting_for_payment
//bill_paid
.task-row{
	> td:nth-child(2), > td:nth-child(3){
		background: #f8f9fa;
	}
	> td:nth-child(3){
		width: 1%;
	}
}
.task-row[data-billingStatus=to_bill],
.task-row[data-billingStatus=waiting_for_acompte],
.task-row[data-billingStatus=waiting_for_complement],
.task-row[data-billingStatus=waiting_for_payment]{
	> td:nth-child(2), > td:nth-child(3){
		background: $appOrangeBg;
	}
}
.task-row[data-billingStatus=paid]{
	> td:nth-child(2), > td:nth-child(3){
		background-color: $appLightGreenBg;
	}
}
