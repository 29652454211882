#main_header{
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	//background: $appDarkGreen;
	background: $appGray;
	margin-left: $containerGutter * -1;
	margin-right: $containerGutter * -1;
	padding-left: $containerGutter;
	padding-right: $containerGutter;
	color: lighten($appGray, 60);
	min-height: 70px;

	a{
		color: inherit;
		text-decoration: none;
		display: inline-block;
		transition: all .2s ease-out;
	}
	a:hover, a:focus{
		color: white;
		//background-color: rgba(0,0,0,.1);
	}

	.side-toggler{
		font-size: 32px;
		margin: 2px 15px 0 -6px;
	}

	h1{
		font-size: 27px;
		padding: 14px 0 5px 0;
	}
}
