
.invoice-preview-wrapper{
	padding-top: round(29.7 / 21 * 100) * 1%; // 21cm * 29.7cm
	position: relative;

	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
	}
}

.invoice-row{
	&.invoice-emitted-true{
		background-color: $appOrangeBg;
	}
	&.invoice-paid-true{
		background-color: $appLightGreenBg;
	}
	&.invoice-emitted-false{
		td{
			color: rgba(0,0,0,.4);
		}
	}
	&.invoice-type-devis{
		background-color: $appBlueIshBg;
	}
}

.invoice-paid-bg{
	background: $appLightGreenBg;
}
.invoice-unpaid-bg{
	background: $appOrangeBg;
}
