
.chart-legend{
	font-size: small;

	.dataset{
		margin-bottom: 16px;
	}
	.dataset:last-child{
		margin-bottom: 0;
	}

	.dataset-item{
		display: flex;
		margin: 3px 0;
	}

	.box{
		flex-shrink: 0;
		flex-basis: 30px;
		margin-right: 8px;
		border-right: 1px inset rgba(0,0,0,.1);
		border-bottom: 1px inset rgba(0,0,0,.1);
		border-left: 1px inset rgba(255,255,255,.1);
		border-top: 1px inset rgba(255,255,255,.1);
		border-radius: 2px;
	}
}
