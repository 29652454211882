
#main_nav{

	a{
		display: block;
		//transition: all .1s ease-out;
	}

	a:hover, a:focus{
		color:white;
		background: black;
	}
	a.active{
		color: $appLightGreen;
		background: black;
	}

	> a{
		padding: 11px $grid-gutter-width /2;
		border-top: 1px solid black;
	}

	.fa, .fas, .far{
		width: 24px;
		text-align: center;
	}

	.collapsable{
		background-color: rgba(0,0,0,.7);
	}
	.collapsable a,
	.secondary a{
		padding: 9px $grid-gutter-width /2 9px 30px;
		font-size: .9em;
	}
}

#main-tabs{
	background: $appGray;
	margin: -16px -20px 20px -20px;
	padding: 16px 0 0 0;

	.nav-tabs .nav-item{

		> a:not(.active){
			color: rgba(255,255,255,.8);
		}
		> a:not(.active):hover,
		> a:not(.active):focus{
			color: rgba(255,255,255,1);
			background: rgba(255,255,255,.1);
			border-color: rgba(255,255,255,.2);
		}
	}

	.nav-tabs .nav-item:first-child{
		margin-left: 20px;
	}
	.nav-tabs .nav-item:last-child{
		margin-right: 20px;
	}
}