
#side{
	background-color: $dark;
	color: darken(white, 20);

	a{
		color:inherit;
		text-decoration: none;
	}
}

#side_header{
	display: flex;
	width: 100%;
	height: 70px;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: 400;
}
