// MISE EN PAGE

html{
	height: 100%;
}
body{
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	height: 100%;
}
h1, h2, h3, b{
	font-weight: 400;
}

#main{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	min-height: 100%;
	min-width: 100%;
}
#side{
	flex-basis: $nav_w;
	flex-shrink: 0;
	overflow: auto;
	position:relative;
	z-index: 900;
	transition: margin $sideTransition;

	// Rattrapage du contenu main sur la gauche
	@include media-breakpoint-down(lg) {
		margin-left: -1 * $nav_w;
	}
}
#main_content{
	flex-grow: 1;
	flex-shrink: 1;
	overflow: auto;
	background: #fcfcfc;
	position: relative;
	z-index: 400;

	> .container-fluid{
		padding-left: $containerGutter;
		padding-right: $containerGutter;
		margin-left: 0;
		transition: margin $sideTransition, width $sideTransition;
	}
}
#main_content_side_overlay{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(255,255,255,.5);
	opacity: 0;
	pointer-events: none;
	transition: opacity $sideTransition;
}

// Toggle side
body.openSide{
	#side{
		margin-left: 0;
	}
	#main_content > .container-fluid{
		margin-left: -1 * $nav_w;
		width: calc(100% + #{$nav_w});
	}
	#main_content_side_overlay{
		opacity: 1;
		pointer-events: all;
	}
}
@include media-breakpoint-up(xl) {
	.side-toggler{
		display: none;
	}
}



// TOOLS

.link-discreet{
	color:inherit;
	text-decoration: none;
}
.link-discreet:hover, .link-discreet:focus{
	color: $link-color;
	text-decoration: none;
}

.outgutter{
	margin-left: $grid-gutter-width /2 * -1;
	margin-right: $grid-gutter-width /2 * -1;
}

.outcard{
	margin-left: $card-columns-gap * -1;
	margin-right: $card-columns-gap * -1;
}

#main-footer{
	margin-top: 150px;
}

.smallblock{
	display: block;
	font-size: small;
	line-height: 1.2em;
	font-weight: 300;
}
.smaller{
	font-size: 10px;
}
h3 .smaller{
	font-size: 12px;
}

.text-ucfirst:first-letter{
	text-transform: uppercase;
}

.pipe-separated >*:after{
	content: " | ";
}
.pipe-separated >*:last-child:after{
	content: "";
}
.text-inherit{
	color: inherit;
}
a.card-header{
	color: inherit;
	text-decoration: none;
	transition: all .15s;
}
a.card-header:hover, a.card-header:focus{
	background: #eeeeee;
}

.nowrap{
	white-space: nowrap;
}
