
// Ajouter des lignes dans les items de card-body
.card-body > [data-prototype] > *:not(:first-child){
	margin-left: -1.25rem;
	margin-right: -1.25rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	padding-top: 1.25rem;
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}
