.btn-light{
	background-color: darken($light, 4);
	border-color: darken($light, 5);
}
.card-header + .card-table, .card-header + .table-responsive {
	margin-top: -1px;
}
.card-table {
	margin-bottom: 0;
}
.input-group-text{
	> *{
		padding-right: 4px;
	}
	> *:last-child{
		padding-right: 0;
	}
}
.card-body + .card-body,
.collapse.show + .card-header,
.collapse.collapsing + .card-header
{
	border-top: 1px solid rgba(0, 0, 0, 0.125);
}
small, .small{
	font-weight: inherit;
}