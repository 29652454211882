$nav_w: 300px;
$dark: #040f0f;
$appDarkGreen: #248232;
$appLightGreen: #2ba84a;
$appLightGreenBg: lighten($appLightGreen, 53);
$appGray: #2d3a3a;
$appWhitish: #fcfffc;
$appBlueIsh: #119da4;
$appBlueIshBg: lighten($appBlueIsh, 62);
$appOrange: #f7d29b;
$appOrangeBg: lighten($appOrange, 17);
$sideTransition: .2s ease-out;
$containerGutter: 20px;
